<template>
  <v-app>
    <v-app-bar color="primary">

      <v-app-bar-nav-icon 
        variant="text"
        @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>

      <v-app-bar-title>
        Manual Navigator <span class="title">{{ code }}</span>
      </v-app-bar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      >
      <v-list>
        <v-list-item title="Home" value="home" @click="$router.push('/')"></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fill-height>
        <router-view />
      </v-container>
    </v-main>

    <v-footer app color="primary">
      岡崎信用金庫 All rights reserved 2024.
    </v-footer>
  </v-app>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const drawer = ref(false);
const code = ref('');

watch(
  () => router.currentRoute.value.query.code,
  (newValue) => {
    code.value = newValue || '';
  }
);
</script>

<style scoped>
.title {
  color: black;

}

@media (min-width: 960px) {
    .v-container {
        max-width: 100% !important;
    }    
}

</style>