<template>
  <div class="job">
    <div class="v-row">
        <div class="v-col-6 manual">
            <div class="wrap">
                <img :src="require('../assets/flow.png')" alt="" class="manualImage">
                <div v-for="item in items" :key="item.id" class="screen" :class="{ active: active === item.id }" :style="{ top: item.y + 'px', left: item.x + 'px', width: item.width + 'px', height: item.height + 'px' }" v-show="active === item.id">
                </div>
            </div>
        </div>
        <div class="v-col-6 checkList">
            <p>チェックリスト</p>
            <div v-for="item in items" :key="item.id">
                <!-- チェックリストのアイテム -->
                <v-checkbox :id="'checkbox-' + item.id" @change="checkItem(item)" :label="item.title" v-model="item.checkFlag"></v-checkbox>
                <p class="remark">{{item.remark}}</p>
                <!-- <input type="checkbox" :id="'checkbox-' + item.id" @change="checkItem(item)"> -->
                <!-- <label :for="'checkbox-' + item.id">{{ item.title }}</label> -->
            </div>
            <v-btn color="primary" @click="redirectToJob" :disabled="active !== 0">次の作業へ</v-btn>
        </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const items = ref([
  { id: 1, title: '初入店か確認', remark: '初入店の場合は管理者を呼ぶこと', checkFlag: false, x: 80, y: 200, width: 100, height: 50 },
  { id: 2, title: '支払いを受け付ける', remark: '支払い方法によっては受付できない場合がある', checkFlag: false, x: 68, y: 265, width: 130, height: 60 },
  { id: 3, title: '説明を行う', remark: '説明はわかりやすく丁寧に', checkFlag: false, x: 68, y: 335, width: 130, height: 80 }
]);

const active = ref(1);
// const setActive = (id) => {
//   active.value = parseInt(id) + 1;
// };

const checkItem = (item) => {
  if (active.value === item.id) {
    // アクティブなアイテムがチェックされた場合、次のアイテムをアクティブにする
    const index = items.value.findIndex(i => i.id === item.id);
    active.value = index === items.value.length - 1 ? 0 : items.value[index + 1].id;
  } else {
    // アクティブなアイテム以外がチェックされた場合、アクティブなアイテムを変更しない
    active.value = item.id;
  }
};

const redirectToJob = () => {
  if (active.value !== items.value[items.value.length - 1].id) {

    // チェックボックスの値をリセット
    items.value.forEach(item => {
        item.checkFlag = false;
    });
    
    // activeを1に戻す
    active.value = 1;
    // const code = active.value + '-2';
    // router.push(`/job?code=${code}`);
    // 現在のコードを取得
    const currentCode = router.currentRoute.value.query.code;
    // ハイフンを区切り文字として分割
    const parts = currentCode.split('-');
    // ハイフンの前の部分を取得
    const prefix = parts[0];
    // ハイフンの後ろの数字を取得し、インクリメント
    const number = parseInt(parts[1]) + 1;
    // 新しいcodeを生成
    const newCode = prefix + '-' + number;
    // ルーターでリダイレクト
    router.push({ name: 'job', query: { code: newCode } });
  }
};

</script>

<style scoped>
.manual {
    border-right: solid 1px gray;
    height: 660px;
}
.manualImage {
    position: absolute;
    top: 85px;
    left: 22px;
    height: 80%;
    z-index: 1;
}

.screen {
    position: absolute;
    z-index: 2;
  border: 3px solid transparent; /* ボーダーカラーは透明に設定 */
}

.active {
  border-color: red; /* activeクラスの時のボーダーカラー */
  animation: blink 1s infinite; /* 点滅アニメーション */
}

@keyframes blink {
  0% { border-color: red; }
  50% { border-color: transparent; }
  100% { border-color: red; }
}

p.remark {
    padding-left: 40px;
    margin-top: -35px;
    margin-bottom: 30px;
}
</style>