<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.jpg')"
          class="my-3"
          contain
          height="60"
        />
        <v-text-field label="作業コードを入力してください" variant="outlined" v-model="code"></v-text-field>
        <v-btn size="x-large" @click="navigateToJob">作業を始める</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const code = ref('');

const navigateToJob = () => {
  if (code.value.trim() !== '') {
    // ルーターを使って /job へ遷移する
    router.push({ name: 'job', query: { code: code.value + '-1' } });
  } else {
    alert('Please enter a code.');
  }
};

</script>
